import { bearlog } from "@sillyscribe95/bear-ui-web";
import { prepareOption } from "./prepareOption";

// 1order
const mainFilter = [
  //
  "order",
  "organise",
  "list",
];

export function orderMap(item, icon = "") {
  const priceOrdr = [
    {
      lab: `Lowest to Highest`,
      val: `${item}-lowtohigh`,
    },
    {
      lab: `Highest to Lowest`,
      val: `${item}-hightolow`,
    },
  ];

  //   1title
  const aswea =
    //
    "⬆ Order ⬇";
  // "order"

  const sidjfre = prepareOption({
    //
    // icon:
    icon,
    subtitle: item,
    title: aswea,
    options: priceOrdr,
    tags: mainFilter,
  });

  return sidjfre;
}
