import React, {
  //
  useState,
  useContext,
} from "react";
import {
  bearlog,
  BearList,
  BearIconList,
  BearDiv,
  BearPage,
  BearBackBorder,
  BearSocialLinks,
  BearSelect,
  BearIconText,
  BearBorder,
} from "@sillyscribe95/bear-ui-web";
import { SearchBar } from "../containers/SearchBar";
import { searchParse } from "../functions/searchParse";
import { selectOS, testParams } from "../const/testConst";
import { ChooseOptions } from "../containers/ChooseOptions";
import { SearchForm } from "../containers/SearchForm";
import { MainPage } from "./MainPage";
import { SearchContext } from "../context/SearchContext";
import { SearchResults } from "../containers/SearchResults";
import { nameFormDict } from "../functions/globalFuncs";

export default function SearchPage() {
  const { searchData, setfinList } =
    //
    // "";
    useContext(SearchContext);

  const nameEo =
    //
    nameFormDict(searchData);

  const nisjaa = {
    metaConfig: {
      title: nameEo,
    },
    // description:
    // image:
  };

  // 1console
  //   bearlog.lug("finList-zzz", finList);

  return (
    <MainPage {...nisjaa}>
      {/* {iconso} */}
      <SearchResults />
      {/* <ButtGo /> */}
    </MainPage>
  );
}
