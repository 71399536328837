//
//
import { getType } from "@sillyscribe95/bear-functions";

// 1sort
export const sortios = {
  "": "Default",
  priceLow: "Price: Lowest to Highest",
  priceHigh: "Price: Highest to Lowest",
  reviewHigh: "Reviews",
  dateNew: "Recently Added",
};

// 1website 1shop
export const shopOptions = {
  amazon: "Amazon",
  bestbuy: "Best Buy",
  target: "Target",
  walmart: "Walmart",
  costco: "Costco",
};

export function getVals(val, dict) {
  const tpyos = getType(val, "object");
  return tpyos ? val : { value: val, label: dict[val] };
}
