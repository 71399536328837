import { bearlog, joinString } from "@sillyscribe95/bear-ui-web";
import { buildLink, check, joinExisting } from "@sillyscribe95/bear-functions";
import { matchTerms } from "./searchParse";

export function parseTarget({
  //
  itemCondition,
  sortType,
  priceBelow,
  priceAbove,
  dealTrue,
  deliveryType,
  includeOutStock,
  ...data
}) {
  //
  const datCombo = priceBelow || priceAbove;
  const datInclude =
    datCombo && "p_36:" + check(priceAbove) + "-" + check(priceBelow);

  // 1conditionv
  const condio = {
    used: "12319068031",
    new: "12319067031",
  }[itemCondition];

  // 1deal

  // 1delivery
  const isdfr =
    deliveryType &&
    {
      sameDay: "5381145031",
      nextDay: "22415809031",
    }[deliveryType];

  const maniReach = joinExisting(
    [
      //
      [includeOutStock, ""],
      [isdfr, `p_90:${isdfr}`],
      [dealTrue, "p_n_specials_match:21583550031"],
      [condio, `p_n_condition-type:${condio}`],
      [datInclude],
    ],
    ","
  );

  const sortos = {
    //
    dateNew: "date-desc-rank",
    reviewHigh: "review-rank",
    priceLow: "price-asc-rank",
    priceHigh: "price-desc-rank",
  }[sortType];

  const sidfjer = matchTerms(data, {
    searchTerm: "k",
    brRefAff: "tag",
    brAffSub: "ascsubtag",
    // dateBefore:
    // dateAfter:
  });

  const countrios = {
    //
  };

  const sdwerase = {
    s: sortos,
    rh: maniReach,
    dc: true,
    ...sidfjer,
  };

  const sfwwr =
    //
    // sdwerase;
    "sfww";
  // sidfjer + datInclude +srot
  //   joinString([
  //     //
  //     matchTerms(sidfjer),
  //     datInclude,
  //   ]);

  const iejrase = buildLink("/s", sdwerase);

  return iejrase;
}
