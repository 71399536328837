import { bearlog, joinString } from "@sillyscribe95/bear-ui-web";
import { buildLink, check, joinExisting } from "@sillyscribe95/bear-functions";
import { matchTerms } from "./searchParse";

// 1url

// https://www.bestbuy.com/site/searchpage.jsp?id=pcat17071&qp=percentdiscount_facet%3DDiscount~All%20Discounted%20Items%5Estorepickupstores_facet%3DStore%20Availability%20-%20In%20Store%20Pickup~463&st=power+bank+charger

// https://www.bestbuy.com/site/searchpage.jsp?id=pcat17071&qp=percentdiscount_facet=Discount~All Discounted Items^storepickupstores_facet=Store Availability - In Store Pickup~463&st=power bank charger

// https://www.bestbuy.com/site/searchpage.jsp?id=pcat17071&qp=condition_facet=Condition~New^currentprice_facet=Price~$150 - $199.99^percentdiscount_facet=Discount~All Discounted Items^storepickupstores_facet=Store Availability - In Store Pickup~463&st=laptop screen

export function parseBestBuy({
  //
  itemCondition,
  sortType,
  priceBelow,
  priceAbove,
  dealTrue,
  deliveryType,
  includeOutStock,
  ...data
}) {
  //
  const datCombo = priceBelow || priceAbove;
  const datInclude =
    datCombo && "$:" + check(priceAbove) + "-" + check(priceBelow);

  // 1conditionv
  const condio = {
    used: "12319068031",
    new: "12319067031",
  }[itemCondition];

  // 1deal

  // 1delivery
  const isdfr =
    deliveryType &&
    {
      sameDay:
        "storepickupstores_facet=Store Availability - In Store Pickup~463",
      nextDay: "",
    }[deliveryType];

  const maniReach = joinExisting(
    [
      //
      // [includeOutStock, ""],
      [isdfr, `p_90:${isdfr}`],
      [
        dealTrue,
        "percentdiscount_facet=Discount~All Discounted Items:21583550031",
      ],
      //   [condio, `p_n_condition-type:${condio}`],
      // [datInclude],
    ],
    "^"
  );

  const sortos = {
    //
    dateNew: "date-desc-rank",
    reviewHigh: "review-rank",
    priceLow: "price-asc-rank",
    priceHigh: "price-desc-rank",
  }[sortType];

  const sidfjer = matchTerms(data, {
    searchTerm: "st",
    // brRefAff: "tag",
    // brAffSub: "ascsubtag",
    // dateBefore:
    // dateAfter:
  });

  const countrios = {
    //
  };

  const sdwerase = {
    // s: sortos,
    qp: maniReach,
    id: "pcat17071",
    // dc: true,
    ...sidfjer,
  };

  const iejrase =
    //
    buildLink(
      "/site/searchpage.jsp",
      //
      sdwerase
      // "^"
    );

  return iejrase;
}
