import { bearlog } from "@sillyscribe95/bear-ui-web";
import { orderMap } from "../functions/orderMap";

// 1price
const sdifwer = orderMap("price", "💰");
// const deliv = filterMap("delivery");
const filtReview =
  //
  [];
// filterMap("reviews", "", {
//   //
// })

bearlog.lug("sdifwer", sdifwer);

export const optionsAmazon = [
  //
  ...sdifwer,
  ...filtReview,
];
