import "./App.css";
import {
  //
  BearInputNumber,
  BearContextProvider,
} from "@sillyscribe95/bear-ui-web";
import SearchPage from "./pages/SearchPage";
import ResultsPage from "./pages/ResultsPage";
import AddPage from "./pages/AddPage";
import SearchContextProvider from "./context/SearchContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TestPage from "./pages/TestPage";
// import VPNPage from "./vpn/VPNPage";

function App() {
  const ksdfer = BearInputNumber({
    min: 20,
  });

  return (
    <>
      <BrowserRouter>
        <BearContextProvider
          //
          // reactRouter
          chakra
        >
          {/*  */}
          <Switch>
            <Route path="/vpn">{/* <VPNPage /> */}</Route>
            <Route path="/search">
              <ResultsPage />
            </Route>
            <Route path="/test">
              <TestPage />
            </Route>
            <Route path="/">
              <SearchPage />
            </Route>
          </Switch>
        </BearContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
