import React, {
  //
  useState,
  useContext,
} from "react";
import {
  BearForm,
  BearDiv,
  bearlog,
  bearMobileView,
  mapFunc,
  BearInputText,
} from "@sillyscribe95/bear-ui-web";
import { useHistory } from "react-router-dom";
import { dateStuff } from "../const/dateConst";
import { optionsAmazon } from "../const/optionsAmazon";
import { searchParse } from "../functions/searchParse";
import { locoCodeo, prodctus, testParams } from "../const/testConst";
import {
  buildLink,
  changePage,
  checkType,
  deleteLocal,
  openNewTab,
  turnDictLink,
  getMinMax,
  setLocal,
} from "@sillyscribe95/bear-functions";
import { SearchContext } from "../context/SearchContext";
import { siteConst } from "../const/siteConst";
import { getVals, shopOptions, sortios } from "../const/formConst";

export function SearchForm({
  //
  deliveryConfig,
  orderConfig,
  ...args
}) {
  // 1const
  let history = useHistory();
  const {
    currCountry,
    listSell,
    setlistSell,
    currSite,
    setcurrSite,
    searchData,
    setSearchData,
    setresultsTrue,
  } =
    //
    // "";
    useContext(SearchContext);

  // 1delivery
  const sdmfsd = {
    inputType: "select",
    dictvar: {
      sameDay: "Today",
      nextDay: "Tomorrow",
      "": "Anytime",
    },
    // options: [
    //   //
    //   { value: "sameDay", label: "Today" },
    //   { value: "nextDay", label: "Tomorrow" },
    //   { value: "", label: "Anytime" },
    //   // {value}
    // ],
  };

  // 1order 1sort
  const sdfwe = {
    // style: { marginTop: "20px" },
    inputType: "select",
    // initialValue: { value: "default", label: "Default" },
    dictvar: sortios,
    name: "sortType",
    label: "⬇️ Sort by",
    ...orderConfig,
  };

  // 1delivery
  const delivall = {
    label: "🚚 Delivery / Pickup",
    name: "deliveryType",
    ...sdmfsd,
    ...deliveryConfig,
  };
  const sijfwr = searchData;

  const dfgws =
    //
    "📋";
  // 1condition
  const sdfiewr = {
    name: "itemCondition",
    label: `${dfgws} Condition`,
    inputType: "select",
    options: [
      { value: "new", label: "New" },
      { value: "used", label: "Used" },
      { value: "", label: "Any" },
    ],
  };

  // 1product 1select
  const jfsre = {
    // padding: ''
    // margin: "",
    noSearchIcon: true,
    inputType: "select",
    showMenuIfValue: true,
    // introMessage: "",
    noOptionsMessage: "nothing here",
    // optionsArray: prodctus,
  };

  const nsder = {
    //
    // height: "400px",
    margin: "40px 0 0px",
    padding: "0 10%",
    // fontSize: "40px",
    // marginBottom: "5px",
  };

  // https://completion.amazon.com/search/complete?search-alias=aps&client=amazon-search-ui&mkt=1&q=iphone
  const [seasf, setseasf] = useState();
  // 1name 1searchterm
  const sjwsra = {
    // ...jfsre,
    name: "searchTerm",
    label: "Search",
    placeholder: "Search for...",
    noLabel: true,
    // onSubmit: ifjer,
    // error: "Noth er ",
    // errorMessage: "Noth er e",
    // required: true,
    // onChange: fxcvoksd,
    onChangeValue: fxcvoksd,
    size: "lg",
    style: nsder,
    // containerStyle: nsder,
  };

  function fxcvoksd(xbdfasdf) {
    // bearlog.lug("xbdfasdf", xbdfasdf);
    setseasf(xbdfasdf);
  }

  // 1price
  function dsijr(adsfoke) {
    return {
      defaultValue: 0,
      noLabel: true,
      inputType: "number",
      positive: true,
      inputLeft: "$",
      leftMargin: "15px",
      // sameLine: true,
      // width: "3000px",
      // style: { },
      // initialValue: "-",
      // default:
      ...adsfoke,
    };
  }

  const iwrqw =
    //
    0;
  // 90;

  const [numMin, setnumMin] =
    //
    // "";
    useState(iwrqw);

  function xvcb(xcvbokfd) {
    //

    bearlog.lug("xcvbokfdzz", xcvbokfd);
    // setnumMin(xcvbokfd);
  }

  // function asdoewq()

  // 1min
  const minios = dsijr({
    label: "Min.",
    name: "priceAbove",
    onChangeValue: xvcb,
  });

  const sfwer =
    //
    numMin;
  // 90;

  const maxios = dsijr({
    name: "priceBelow",
    // onChange: xvcb,
    defaultValue: sfwer,
    min: sfwer,
  });

  // 1minPrice
  const difewrqw = {
    ...minios,
    defaultValue: "0.00",
    noLabel: false,
    label: "💰 Minimum Price",
  };

  const sfdgret = {
    horizList: [minios, maxios],
    label: "💰 Price Range",
    // labelStyle: {
    //   marginTop: "10px",
    // },
    labelConfig: {
      style: {
        marginTop: "30px",
      },
    },
  };

  // 1price
  const numbio =
    //
    sfdgret;
  // difewrqw;

  //  1website
  const ijters = {
    noLabel: true,
    // containFunc:
    multi: true,
    onChangeValue: sdfjsd,
    style: {
      textAlign: "left",
      margin: "0 10%",
    },
    label: "The fastest way to search...",
    sameLine: true,
    name: "website",
    fontSize: "26px",
    // label: "Website",
    width: "200px",
    // fontSize: "30px",
    inputType: "select",
    dictvar: shopOptions,
  };

  function sdfjsd(radas) {
    bearlog.lug("radas-zzz", radas);
    // setChange()
  }

  function asdW(xvxcv) {
    return {
      // horizList:
      style: {
        padding: "0 5%",
      },
      ...xvxcv,
    };
  }

  // 1dictvar:
  const sdifre = {
    lineTop: asdW({ horizList: [ijters, sjwsra] }),
    // lineFind: { horizList: [] },
    lineOne: asdW({ horizList: [sdfwe, numbio] }),
    lineTwo: asdW({ horizList: [sdfiewr, delivall] }),
    delivery: delivall,
    sort: sdfwe,
    priceRange: numbio,
    condition: sdfiewr,
    searchTerm: sjwsra,
    maxPrice: maxios,
    website: ijters,
  };

  // 1create
  function additoas(asdiew) {
    //
    bearlog.lug("searchCraete", asdiew);
  }

  // 1submit CHANGE
  function sfsar({
    //
    // website,
    bearName,
    ...dsfs
  }) {
    //
    // setSearchData(dsfs);
    let sease =
      //
      "/search" + turnDictLink(dsfs);
    // buildLink("", dsfs);

    bearlog.lug("sease--zzz", { dsfs, sease });

    history.push(sease);
    // changePage();
    // setresultsTrue(true);
  }

  function dffwer(prive) {
    // const {min: priceAbove, } getMinMax(sdfoe,rokae)
  }

  function ifjer({ data, values }) {
    bearlog.lug("SUBMGO--", { data, values });
    localO(data);
    xcvbodg(values);
  }

  // 1submit NEW LINK
  function xcvbodg(sdfer) {
    bearlog.lug("SUBMGO--", sdfer);

    const { min: priceAbove, max: priceBelow } = getMinMax(
      sdfer.priceAbove,
      sdfer.priceBelow
    );

    sdfer = {
      ...sdfer,
      priceAbove,
      priceBelow,
      searchTerm: seasf,
    };

    bearlog.lug("sbb AFTo--", sdfer);

    const sdijfrwer =
      //

      sdfer.searchTerm;

    if (sdijfrwer) {
      sbsfdg(sdfer);
    } else {
      // setErro
    }
  }
  // 1submit MAIN
  function sbsfdg(zxcvs) {
    const extraFin =
      //
      testParams;

    zxcvs = {
      ...extraFin,
      ...zxcvs,
    };

    bearlog.lug("onsubmo SELE---", zxcvs);

    sgewsad(zxcvs);
    // sfsar(zxcvs);

    // setMainType;
    // setOptList()
  }

  // 1local
  function localO({ sortType }) {
    const sdre = { sortType: getVals(sortType, sortios) };
    bearlog.lug("sdre-zzz", sdre);
    setLocal(locoCodeo, sdre);
    // deleteLocal(locoCodeo, sdre);
  }

  // 1newtab
  function sgewsad({ website, bearName, ...asdf }) {
    asdf["userCountry"] =
      //
      "us";
    // currCountry;

    website =
      //
      // website
      // "walmart";
      "amazon";

    let { link } =
      //
      // "?@34okwer4qwe4";
      searchParse(website, asdf);

    bearlog.lug("sdfer-zzz", link, asdf);
    openNewTab(link);
  }

  function Csaoasse(dfsdadse) {
    const sdsaswe = `Search for "${dfsdadse}"`;
    return sdsaswe;
  }

  function asdf() {}
  const aijsqe = {
    imagevar: "",
  };

  const sfojras = {
    margin: "0 0",
    // width: "90%",
    // padding: "0px 10px",
  };

  // 1hover
  const kvxcfs = {
    // background: "transparent",
  };

  const digrwed =
    //
    // true;
    bearMobileView;

  const sdifjwer = digrwed
    ? [
        //
        "priceRange",
        "delivery",
        "condition",
        "sort",
      ]
    : [
        //
        "lineOne",
        "lineTwo",
      ];

  //  1list
  const vbijsew = [
    // "website",
    "searchTerm",
    "formButton",
    ...sdifjwer,
    // "delivery",
    //
  ];

  const sfgwe = {
    // background: "red",
    padding: "0 1%",
  };

  //   1input
  const vcbss = {
    style: sfgwe,
    labelWidth: "140px",
    noOptionsMessage: false,
    // sameLine: true,
  };

  //   1button
  const xvokf = {
    //
    style: {
      fontSize: "40px",
      margin: "40px 0 60px",
      padding: "0 10%",
    },
  };

  const sdfwer = {
    //
    aligne: "center",
    labelStyle: { fontSize: "24px" },
    isClearable: false,
    style: sfojras,
    spaceBetween: 0,
    clearValue: () => null,
    // hideSearchIcon: true,
  };

  // 1title
  const sidferw =
    //
    "";
  // <div style={{ fontSize: "40x" }}>The smart way to search Amazon deals.</div>

  const aeasea = {
    list: vbijsew,
    extractValues: true,
    // title: ,
    formid: "2ijqweas",
    bearName: "bearName",
    dictvar: sdifre,
    onSubmit: ifjer,
    ...vcbss,
    inputConfig: sdfwer,
    labelConfig: { align: "left" },
    formData: sijfwr,
    focusInput: "searchTerm",
    buttonText: "Search   🐻",
    buttonConfig: xvokf,
    noButtonEnd: true,
    // ...sidjer,
  };

  bearlog.lug("search FORM", aeasea);

  // 1input
  function Inipio() {
    const difgjert = {
      ...sjwsra,

      // type: "submit",
    };

    return BearInputText(difgjert);
  }

  const okfdsd = (
    <>
      {/*  */}
      {sidferw}
      {/* {Inipio()} */}
      {BearForm(aeasea)}
    </>
  );

  const sijdrw = !bearMobileView && { padding: "0 5%" };
  const sdfewr = {
    style: {
      ...sijdrw,
      margin: "40px 15px 0",
    },
  };

  return <div {...sdfewr}>{okfdsd}</div>;
}
