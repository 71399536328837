import { bearlog } from "@sillyscribe95/bear-ui-web";
import { getDictvalues, getLocal } from "@sillyscribe95/bear-functions";
import { searchParse } from "../functions/searchParse";

// 1testtrue
const testTrue =
  //
  "";
// true

// 1affiliate
const sijr =
  //
  "";
// aff
// rid
// ref
// refid

const dateBef =
  //
  new Date();

const datAft =
  //
  "new";

const prBelow =
  //
  1200;

const prAbove =
  //
  "";
// 1700;

// 1amazon
//

// https://www.amazon.com/s?k=power+bank+charger&rh=p_n_specials_match%3A21583550031%2Cp_36%3A1200-%2Cp_76%3A1-&dc&qid=1628279433&ref=sr_nr_p_76_1\

const timeCurr = new Date().getHours();
const timeover = timeCurr < 15;

bearlog.lug("timeos=", { timeCurr, timeover });

// 1
// const review =

const delivo =
  //
  // ""
  timeover
    ? { value: "sameDay", label: "Today" }
    : { value: "nextDay", label: "Tomorrow" };

// 1local
export const locoCodeo = "sbDt--";
const logGet = getLocal(locoCodeo);
// 1sort
const isjdfa =
  //
  { value: "", label: "Default" };
// { value: "reviewHigh", label: "Reviews" }
// { value: "priceLow", label: "Price: Lowest to Highest" };
// { value: "priceHigh", label: "Price: Highest to Lowest" }
// { value: "dateNew", label: "Recently Added" }

export const defaultTerms = {
  sameDay: "same day",
  nextDay: "next day",
};

const perfectAma = {
  //
};

const testAma = {
  ...perfectAma,
  // dateBefore: dateBef,
  // dateAfter: datAft,
  // priceBelow: prBelow,
  // priceAbove: prAbove,
};

// 1ref
const isjfsae = {
  brRefAff: "097-20",
  brAffSub: "v7_1_3_2q15_5j5w_0_x01_-srt1-",
};

// 1general
const testGen = {
  //
  // dealTrue: true,
  highReviews: true,
  itemCondition: { value: "", label: "Any" },
  deliveryType: delivo,
  sortType: isjdfa,
  ...logGet,
};

// 1console
bearlog.lug("--testGen--- DATA --zzz", { logGet });

const testSite =
  //
  {
    ...testGen,
    ...testAma,
  };

const testBeans = testTrue && {
  searchTerm: "power bank charger",
};

// 1country
const nsdijewt =
  //
  // "uk";
  "us";

const beanios = {
  // userCountry: nsdijewt,
  ...testBeans,
  ...testSite,
};

// 1test
export const testParams = {
  ...beanios,
  website: [
    //
    { value: "bestbuy", label: "Best Buy" },
    { value: "amazon", label: "Amazon" },
  ],
};

// 1list
export const selectOS = [
  {
    name: "Amazon",
    type: "amazon",
  },
  {
    name: "Walmart",
    type: "walmart",
  },
  {
    name: "Best Buy",
    type: "bestbuy",
  },

  // {
  //   name: "Target",
  //   type: "target",
  // },
].map(sadjqwe);

function sadjqwe(sdfoewr) {
  return {
    ...searchParse(sdfoewr.type, getDictvalues(beanios)),
    ...sdfoewr,
  };
}
