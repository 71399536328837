import { bearlog } from "@sillyscribe95/bear-ui-web";
import { getLinkParams, getParamVar } from "@sillyscribe95/bear-functions";
import React, { useEffect, useRef, useState, createContext } from "react";
import { locSites } from "../const/siteConst";
import { testParams } from "../const/testConst";
import { nameFormDict } from "../functions/globalFuncs";
import useGeoLocation from "react-ipgeolocation";
import { countryConst, countryFull } from "../const/countryConst";
export const SearchContext = createContext();

const SearchContextProvider = (props) => {
  //   1const
  const [tim, settim] = useState();
  const [listSell, setlistSell] = useState();
  const [preferSites, setpreferSites] = useState();

  const [currSite, setcurrSite] = useState(locSites);

  const sirwas =
    //
    // "";
    getLinkParams();
  // getParamDict()

  //   1dataform
  const sijfwr = {
    ...testParams,
    ...sirwas,
    website: currSite,
  };

  const [searchData, setsearchData] = useState(sijfwr);

  const trous =
    //
    // true;
    getParamVar("searchTerm");

  const nameFromData =
    //
    "";
  // nameFormDict(searchData);

  const resultsTrue =
    //
    trous;
  const [sdfgore, setresultsTrue] = useState(trous);

  // 1location

  //somewhere in your app/component
  const { country, loading: loadCountry } = useGeoLocation();

  let countBase = country && country.toLowerCase();
  countBase = countBase ? countBase : "us";
  // bearlog.glo;
  let xcvsdf = country && countryConst[countBase];
  // xcvsdf = xcvsdf ? xcvsdf : "us";
  let currCountry = loadCountry
    ? ""
    : xcvsdf
    ? xcvsdf
    : //
      "";
  // countryConst["us"];

  let countName =
    //
    // "Cannada";
    // currCountry && currCountry["name"];
    country && countryFull[countBase] && countryFull[countBase]["name"];

  // 1test
  const testTrue =
    //
    // "asqewe";
    "";
  //

  function testCheck(bitOne, bitTwo) {
    return testTrue ? bitOne : bitTwo;
  }

  // 1form
  const [viewType, setviewType] = useState();

  // 1console
  const baseScript = {
    base: {
      searchData,
      country,
      xcvsdf,
      currCountry,
      // LNKPARMAS: sirwas,
      // nameFromData,
      //   hereo: hereo,
    },
    form: {
      //
    },
  };

  const okwe =
    //
    "base";

  const osdre = okwe ? baseScript[okwe] : baseScript;

  bearlog.lug("context ", osdre);

  const xvokbcv = {
    countName,
    currCountry,
    resultsTrue,
    setresultsTrue,
    searchData,
    setsearchData,
    currSite,
    setcurrSite,
    preferSites,
    setpreferSites,
    listSell,
    setlistSell,
    tim,
    settim,
    viewType,
    setviewType,
    testCheck,
    // FORM
  };

  // 1return 1end
  return (
    <SearchContext.Provider
      //
      value={xvokbcv}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
// }

export default SearchContextProvider;
