import { bearlog, mapFunc } from "@sillyscribe95/bear-ui-web";
// import { openNewTab } from "@sillyscribe95/bear-functions";
import { parseAmazon } from "./parseAmazon";
import { parseBestBuy } from "./parseBestBuy";
import { parseTarget } from "./parseTarget";
import { parseEbay } from "./parseEbay";
import { parseWalmart } from "./parseWalmart";
import { countryConst } from "../const/countryConst";

function openNewTab(tboo, nummo, config) {
  window.open(tboo, nummo, config);
  // setTimeout(function(){
  //   window.open("https://facebook.com", "two", windowFeatures);
  // }, 500);
}

export function searchParse(type, oskdf) {
  oskdf["priceAbove"] =
    //
    // 2500
    parseFloat(oskdf.priceAbove);

  const link = sdofke(type, oskdf);
  return { type, link };
}

export function sdofke(type, { userCountry, ...sdfsd }) {
  bearlog.lug(type, "---searchParse", sdfsd);

  let linko;

  switch (type) {
    case "bestbuy":
      linko = parseBestBuy(sdfsd);
      break;
    case "amazon":
      linko = parseAmazon(sdfsd);
      break;
    // case "ebay":
    //   linko = parseEbay(sdfsd);
    //   break;
    // case "etsy":
    //   linko = parseEtsy(sdfsd);
    //   break;
    case "target":
      linko = parseTarget(sdfsd);
      break;
    case "walmart":
      linko = parseWalmart(sdfsd);
      break;
    // case "twitter":
    //     linko = parseTwitter()
    // case "google":
    //     linko = parseGoogle()
  }

  linko = getCountry(type, userCountry) + linko;

  // if (linko) {
  //   bearlog.lug("sarchPrse LINK---", linko);
  //   openNewTab(linko);
  // }

  return linko;
}

// export function baseParse() {
//   linko = getCountry(type, userCountry) + linko;

//   return linko;
// }

// 1target
// https://www.target.com/s?searchTerm=power+bank+charger&facetedValue=cl92v&Nao=0&sortBy=RatingHigh

// https://www.amazon.co.uk/s?k=charging+cable&rh=p_36%3A2000-10000&s=date-asc-rank&qid=1628183848&rnid=428432031
// https://www.amazon.co.uk/s?k=charging+cable&rh=p_36%3A2000-10000&s=price-asc-rank&qid=1628183320&rnid=428432031&ref=sr_st_price-asc-rank

// 1amazon
//www.amazon.co.uk/s?k=charging cable&rh=p_36:2000-10000&qid=1628167212&rnid=428432031&ref=sr_nr_p_36_6

export function matchTerms(xcv, saosk, aak = {}) {
  return sidajf(xcv, saosk, aak);
}

export function sidajf(mainData, prTerms) {
  let fullurl =
    //
    {};
  // "";
  const oskase = {
    //
    ...prTerms,
  };

  bearlog.lug("mahTerms--", { mainData, prTerms });

  for (const [key, value] of Object.entries(mainData)) {
    bearlog.lug(key, value);

    if (value) {
      const kyurl = oskase[key];
      const vlurl = value;

      // const addval = kyurl + joinVal + vlurl;
      // bearlog.lug("addval", addval);
      // fullurl += addval;

      fullurl[kyurl] = vlurl;
    }
  }

  bearlog.lug("fullurl", fullurl);

  return fullurl;
}

export function getCountry(type, country = "us") {
  const isjase = {
    amazon: {
      //
    },
    ebay: {
      //
    },
    walmart: {},
  }[type];

  const serase = {
    // countrJson[country],
    ...countryConst,
    ...isjase,
  };

  const sdferw = serase[country]["tld"];

  const sdlase = `https://${type}.${sdferw}`;

  return sdlase;
}

// 1amazon

// 1etsy

// 1ebay

// 1walmart

// 1bestbuy
// https://www.bestbuy.com/site/searchpage.jsp?st=power+bank+charger&_dyncharset=UTF-8&_dynSessConf=&id=pcat17071&type=page&sc=Global&cp=1&nrp=&sp=&qp=&list=n&af=true&iht=y&usc=All+Categories&ks=960&keys=keys

// 1extra 1main
export function sortChoose(param, srtType) {
  const dijfewr = "s=";
}
