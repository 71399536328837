import React, {
  //
  useState,
  useContext,
} from "react";
import {
  bearlog,
  BearList,
  BearIconList,
  BearDiv,
  BearTitle,
  BearPage,
  BearBackBorder,
  BearSocialLinks,
  BearSelect,
  BearIconText,
  BearBorder,
} from "@sillyscribe95/bear-ui-web";
import { SearchBar } from "../containers/SearchBar";
import { searchParse } from "../functions/searchParse";
import { selectOS, testParams } from "../const/testConst";
import { ChooseOptions } from "../containers/ChooseOptions";
import { SearchForm } from "../containers/SearchForm";
import { MainPage } from "./MainPage";
import { SearchContext } from "../context/SearchContext";
import { SearchResults } from "../containers/SearchResults";
import { nameFormDict } from "../functions/globalFuncs";

export default function SearchPage() {
  const sdfwer = [
    //
    "amazon",
    "twitter",
    "google",
  ];

  const { resultsTrue, searchData, listSell, setfinList } =
    //
    // "";
    useContext(SearchContext);

  const finList =
    //
    resultsTrue;
  // selectOS;
  // listSell;

  // const [asdkeo, setasdkeo] = useState();

  // openGo("amazon");
  // openGo("walmart");
  // bearlog.lug("werfasd", ));

  function asesdfs({ itemName }) {
    openGo(itemName);
  }

  function openGo(itemName) {
    bearlog.lug("click searhch TYPE", itemName);

    let dsofaase =
      //
      testParams;
    // optList

    if (itemName) {
      return searchParse(itemName, dsofaase);
    }
  }

  const sijrase = {
    list: sdfwer,
    bearName: "listSOci",
    typeList: "button",
    horiz: true,
    onClick: asesdfs,
    itemConfig: {
      noText: true,
      className: "expandHover",
    },
  };

  //   1lucky

  const isrre =
    //
    // "🐻 Feeling Lucky?"
    "🐻 Let Find";

  const lckmain = {
    textvar: isrre,
    background: "blue",
  };

  function ButtGo() {
    const nsdif = {
      search: {
        iconvar: "search",
        textvar: "Search",
        background: "green",
      },
      lucky: lckmain,
    };

    const sfeae = {
      className: "buttonHover",
      fontSize: "28px",
      color: "white",
    };

    const ijrase = {
      typeList: "button",
      dictvar: nsdif,
      list: ["search", "lucky"],
      // onClick: searGo,
      onClick: asesdfs,
      horiz: true,
      itemConfig: sfeae,
      bearName: "socisal",
    };

    function searGo({ itemName }) {
      bearlog.lug("searGo", itemName);
    }

    return BearList(ijrase);
  }

  const iconso =
    //
    BearList(sijrase);
  // BearSocialLinks(sijrase)

  function Swae() {
    const sidjrew = BearSelect({
      // options
    });

    const isjad = {
      fontSize: "40px",
    };

    const idfj = (
      <BearDiv flex {...isjad}>
        The fastest way to search {sidjrew}
      </BearDiv>
    );

    return idfj;
  }

  const sdifjew = BearTitle({
    fontSize: "20px",
    children: "The smart way to search Amazon.",
  });

  const nfqwe = (
    <>
      {sdifjew}
      <SearchForm />
      {/* <SearchBar /> */}
      {/* <ChooseOptions /> */}
    </>
  );

  const nameEo =
    //
    "Amazon SuperSearch";
  // "The fastest way to find same day delivery deals";

  const nisjaa = {
    align: "center",
    metaConfig: {
      title: nameEo,
    },
    // description:
    // image:
  };

  // 1console
  bearlog.lug("finList-zzz", finList);

  return (
    <MainPage {...nisjaa}>
      {/* {iconso} */}
      {nfqwe}
      {/* <ButtGo /> */}
    </MainPage>
  );
}
