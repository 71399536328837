import { getLocal } from "@sillyscribe95/bear-functions";

export const siteConst = {
  amazon: {
    name: "Amazon",
  },
  bestbuy: {
    name: "Best Buy",
  },
  walmart: {
    name: "Walmart",
  },
  costco: {
    name: "Costco",
  },
};

export const siteStoreID = "odsfgk213qw";
const siteoas =
  //
  getLocal(siteStoreID);

const sdfinwer = [
  { value: "amazon", label: "Amazon" },
  { value: "walmart", label: "Walmart" },
];

export const locSites =
  //
  siteoas ? siteoas : sdfinwer;
