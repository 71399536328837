import React, {
  //
  useState,
  useContext,
} from "react";
import {
  //
  logs,
  BearDiv,
  BearBorder,
  BearList,
  BearBackBorder,
  BearSocialLinks,
  BearSocialShare,
  bearlog,
  BearBlock,
  bearMobileView,
  BearLine,
  BearButton,
} from "@sillyscribe95/bear-ui-web";
import { SearchContext } from "../context/SearchContext";
import { getRandomArray, mapFunc } from "@sillyscribe95/bear-functions";
import { searchParse } from "../functions/searchParse";
import { siteConst } from "../const/siteConst";

export function SearchResults(listoa) {
  // 1const
  const { listSell, searchData } = useContext(SearchContext);

  // 1title
  const asdiwe =
    //
    // "";
    getRandomArray([
      //

      "We found your deals!",
    ]);

  function asdjfe({ name, link, type }) {
    const sdijwer = (
      //
      <>
        {name}
        {/*  */}
        <br />
        {/* <div style={{ fontSize: "0.4em" }}>{decodeURI(link)}</div> */}
      </>
    );
    // BearIconText("link", name);

    const ijcewwe = {
      className: "buttonHover",
      outsideLink: link,
      style: {
        // width: "140px",
      },
      obj: sdijwer,
    };

    return BearDiv(ijcewwe);
  }

  const sidjfer =
    //
    "3.8vw";

  // 1list
  const sdifjwer =
    //
    // xcvbg
    // [];
    mapFunc(searchData.website, sdijfew);

  bearlog.lug("sdifjwer-zzz", sdifjwer);

  // sfgoe
  // listoa
  function sdijfew({ value: wqq3 }, index) {
    const sdijfwe = searchParse(wqq3, searchData);

    bearlog.lug("sdijfwev-", sdijfwe);
    // window.open(sdijfwe, wqq3);
    // chrome.tabs.create({
    //   url: sdijfwe,
    // });
    // 1website
    return {
      //
      ...siteConst[wqq3],
      type: wqq3,
      ...sdijfwe,
    };
  }

  const sdijfewr = {
    style: {
      margin: "0 20%",
    },
    title: asdiwe,
    horiz: !bearMobileView,
    ignoreEmpty: true,
    list: sdifjwer,
    bearName: "2okqweqw",
    renderItem: asdjfe,
    itemConfig: {
      fontSize: sidjfer,
      style: {
        margin: "20px 0",
        ...BearBackBorder("5px", "grey"),
        textAlign: "center",
      },
    },
  };

  const nisjrwq = BearList(sdijfewr);

  // 1share
  function Shaeoas() {
    const sdfwrase =
      //
      "searchTerm";

    const sijfwer = [
      //
      "facebook",
      "twitter",
      "whatsapp",
      // "telegram",
    ];

    const irwaesew = {
      //
      bearName: "4i2131sae",
      shareLink: "shareLink",
      shareText: sdfwrase,
      horiz: true,
      list: sijfwer,
    };

    const sijwer = BearSocialShare(irwaesew);

    const gwerwse = (
      //
      <BearBlock
        //
        title="Share your deals"
      >
        {sijwer}
      </BearBlock>
    );

    return gwerwse;
  }

  // 1button 1new
  function Buttoas() {
    const isdrase = {
      //
      background: "green",
      color: "white",
      fontSize: "28px",
      style: { padding: "0 3%" },
    };

    return (
      <BearButton {...isdrase}>
        {/*  */}
        Search Again
      </BearButton>
    );
  }

  const okfdsd = (
    <>
      {/*  */}
      {nisjrwq}
      {Buttoas()}
      <BearLine marginBottom="40px" />
      {Shaeoas()}
    </>
  );

  const zsdoakfer = {
    style: { textAlign: "center" },
  };

  return <div {...zsdoakfer}>{okfdsd}</div>;
}
