import React, {
  //
  useState,
  useContext,
} from "react";
import {
  capitalizeFirstLetter,
  checkType,
  maplisttostring,
} from "@sillyscribe95/bear-functions";
import { BearBorder, BearDiv, bearlog } from "@sillyscribe95/bear-ui-web";
import { SearchContext } from "../context/SearchContext";

export function PrepDiv({
  //
  lab,
  subtitle,
  title,
  icon,
  ...args
}) {
  // 1const
  //   const { viewType, setviewType } = useContext(SearchContext);

  const ssdfer = {
    style: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  };

  const vdaew = {
    //
    ...BearBorder("black", "5px"),
    background: "lightblue",
    // padding: "10px",
    marginRight: "10px",
  };

  const sdjfwr = {
    style: vdaew,
  };

  const isjewr = capitalizeFirstLetter(title);

  const soerse = (
    <BearDiv span {...sdjfwr}>
      {/*  */}
      {isjewr}
    </BearDiv>
  );

  const xbofdg = capitalizeFirstLetter(subtitle);

  const erwse = (
    <div {...ssdfer}>
      {/*  */}
      {soerse}
      {icon} {xbofdg} - {lab}
    </div>
  );

  const sdfser = {
    // style: vdaew,
  };

  const sdijer = (
    <BearDiv {...sdfser}>
      {/*  */}
      {erwse}
      {/* asdawqok */}
    </BearDiv>
  );

  return sdijer;
}
