import { bearlog, joinString } from "@sillyscribe95/bear-ui-web";
import { buildLink, check, joinExisting } from "@sillyscribe95/bear-functions";
import { matchTerms } from "./searchParse";

//www.amazon.co.uk/s?k=power+bank&ref=sugsr_0_2&pd_rd_w=6SuCN&pf_rd_p=7c89f663-1851-4f5c-ad06-b2fd7b71af3e&pf_rd_r=PN3Z5CC5W8P4HQPPZ5JD&pd_rd_r=f629acdc-f61e-4436-af24-d81f6bbea7a4&pd_rd_wg=RseJr&qid=1628965033

// https://amazon.com/s?rh=p_90:5381145031,p_n_specials_match:21583550031,p_n_condition-type:12319067031,p_36:0-0f&dc&k=power bank charger

// 1url
// https://www.amazon.co.uk/s?k=power bank charger&rh=p_90:5381145031,p_n_availability:419162031,p_n_specials_match:21583550031&s=review-rank&dc

// https://www.amazon.co.uk/s?k=power bank charger&rh=p_90:5381145031,p_n_availability:419162031&s=review-rank&dc

// https://www.amazon.co.uk/s?k=power bank charger&rh=p_90:5381145031,p_n_availability:419162031&s=review-rank&dc

// https://www.amazon.co.uk/s?k=power bank charger&rh=p_90:5381145031,p_n_availability:419162031&s=review-rank&dc&qid=1628323239&rnid=21583549031&ref=sr_nr_p_n_specials_match_1

//www.amazon.co.uk/s?k=power bank charger&rh=p_90:5381145031&s=review-rank&dc

export function parseAmazon({
  //
  itemCondition,
  sortType,
  priceBelow,
  highReviews,
  priceAbove,
  dealTrue,
  deliveryType,
  includeOutStock,
  ...data
}) {
  function chuck(fsdaf) {
    return fsdaf ? fsdaf * 100 : "";
  }

  // 1price
  const datCombo = priceBelow || priceAbove;
  const datInclude =
    datCombo && "p_36:" + chuck(priceAbove) + "-" + chuck(priceBelow);

  // 1conditionv
  const condio = {
    used: "12319068031",
    new: "12319067031",
  }[itemCondition];

  // 1deal

  // 1delivery
  const isdfr =
    deliveryType &&
    {
      sameDay: "5381145031",
      nextDay: "22415809031",
    }[deliveryType];

  const maniReach = joinExisting(
    [
      //
      [includeOutStock, ""],
      [highReviews, "p_72:2491149011"],
      [isdfr, `p_90:${isdfr}`],
      [dealTrue, "p_n_specials_match:21583550031"],
      [condio, `p_n_condition-type:${condio}`],
      [datInclude],
    ],
    ","
  );

  const sortos = {
    //
    dateNew: "date-desc-rank",
    reviewHigh: "review-rank",
    priceLow: "price-asc-rank",
    priceHigh: "price-desc-rank",
  }[sortType];

  const sidfjer = matchTerms(data, {
    searchTerm: "k",
    // brRefAff: "tag",
    // brAffSub: "ascsubtag",
    // dateBefore:
    // dateAfter:
  });

  const countrios = {
    //
  };

  const tgINfo = {
    tag: "sb210b-20",
  };

  const sdwerase = {
    s: sortos,
    rh: maniReach,
    dc: true,
    ...tgINfo,
    ...sidfjer,
  };

  const iejrase = buildLink("/s", sdwerase);

  return iejrase;
}
