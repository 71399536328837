import React, {
  //
  useState,
  useContext,
} from "react";
import {
  //
  logs,
  BearDiv,
  BearPage,
  bearMobileView,
  BearLink,
  BearSelect,
} from "@sillyscribe95/bear-ui-web";
import { mapDictAttr } from "@sillyscribe95/bear-functions";
import { countrJson } from "../const/countJson";
import { SearchContext } from "../context/SearchContext";

export function MainPage({
  //
  // children,
  metaConfig,
  ...args
}) {
  // 1const
  const { countName, currCountry, viewType, setviewType } =
    useContext(SearchContext);

  const okfdsd = (
    <>
      {/*  */}
      sssss
    </>
  );

  // 1header
  const sdfsre = {
    login: {
      link: "login",
      textvar: "Login",
    },
    signup: {
      background: "darkgreen",
      color: "white",
      link: "signup",
      textvar: "Sign Up",
    },
  };

  const sdifwer = (
    <>
      {/*  */}
      The smart way to search Amazon.
      {/* Made with love by BearUI 🐻 */}
    </>
  );

  const sdfijer = {
    children: sdifwer,
    // outsideLink: "http://bearui.io/",
    fontSize: "22px",
  };

  // 1bear
  const sdfewr = BearDiv(sdfijer);

  // 1logo
  const jsde = { className: "buttonHover" };

  function Beario() {
    const sdire = mapDictAttr({}, countrJson, "name");

    const dsfgre = {
      width: "200px",
      notClearable: true,
      initialValue: countName,
      // initialValue: {
      //   value: currCountry,
      //   label: countrJson[currCountry]["name"],
      // },
      dictvar: sdire,
    };

    return BearSelect(dsfgre);
  }

  // function Namer(){
  //   const
  //   return BearButton()
  // }

  const sjrewas =
    //
    "UK";
  // currCountry && countName;
  // Beario();

  const isdfre = (
    <BearDiv flex>
      {/*  */}
      🐻 SearchBear {sjrewas}
    </BearDiv>
  );

  args = {
    align: "center",
    heightBottom: "200px",
    metaConfig: {
      description: "Search Amazon the smart way with Searchbear.",
      // image: "logo192.png",
      siteName: "SearchBear",
      ...metaConfig,
    },
    headerConfig: {
      // icon: "",
      // height:
      logoConfig: jsde,
      // name: "SearchBear",
      // logo: "🐻",
      left: !bearMobileView && isdfre,
      center: bearMobileView && isdfre,
      right: !bearMobileView && sdfewr,
      // navConfig: {
      //   bearName: "headeors",
      //   list: ["login", "signup"],
      //   dictvar: sdfsre,
      //   itemConfig: {
      //     className: "buttonHover",
      //     style: { margin: "0 10px" },
      //   },
      // },
    },
    ...args,
  };

  return <BearPage {...args} />;
}
