import React, {
  //
  useState,
  useContext,
} from "react";
import {
  bearlog,
  BearList,
  BearIconList,
  BearDiv,
  BearPage,
  BearBackBorder,
  BearSocialLinks,
  BearSelect,
  BearIconText,
  BearBorder,
} from "@sillyscribe95/bear-ui-web";
import { SearchBar } from "../containers/SearchBar";
import { searchParse } from "../functions/searchParse";
import { selectOS, testParams } from "../const/testConst";
import { ChooseOptions } from "../containers/ChooseOptions";
import { SearchForm } from "../containers/SearchForm";
import { MainPage } from "./MainPage";
import { SearchContext } from "../context/SearchContext";
import { SearchResults } from "../containers/SearchResults";
import { nameFormDict } from "../functions/globalFuncs";
// import { countAlldat } from "../const/countryConst";

export function maparrtodict(array, att = "") {
  let ofkaw = {};
  const asokew = array.map(function (sdfwer) {
    let oskfdw = sdfwer[att];
    ofkaw[oskfdw] = sdfwer;
  });

  return ofkaw;
}

export default function TestPage() {
  const { searchData, setfinList } =
    //
    // "";
    useContext(SearchContext);

  const sjkdwe = "";
  const nisjaa = {};

  // 1console
  bearlog.lug("sjkdwe-zz", sjkdwe);
  //   bearlog.lug("finList-zzz", finList);

  return (
    <MainPage {...nisjaa}>
      {/* {iconso} */}
      ASDQW
      {/* {sjkdwe} */}
      {/* <ButtGo /> */}
    </MainPage>
  );
}
