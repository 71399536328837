import { bearlog, joinString } from "@sillyscribe95/bear-ui-web";
import { buildLink, check, joinExisting } from "@sillyscribe95/bear-functions";
import { matchTerms } from "./searchParse";

// 1url

// https://www.walmart.com/search/?cat_id=0&facet=price:$20 - $50||pickup_and_delivery:2-Day Shipping&page=1&query=power bank charger&sort=rating_high

// https://www.walmart.com/search/?cat_id=0&facet=price:$20 - $50||pickup_and_delivery:FREE Pickup Today&query=power bank charger&sort=rating_high

// https://www.walmart.com/search/?cat_id=0&facet=pickup_and_delivery%3A2-Day+Shipping&query=power+bank+charger

export function parseWalmart({
  //
  itemCondition,
  sortType,
  //   priceBelow,
  //   priceAbove,
  dealTrue,
  deliveryType,
  includeOutStock,
  ...data
}) {
  //
  //   price:$20 - $50
  //   const datCombo = priceBelow || priceAbove;
  //   const datInclude =
  //     datCombo && "price:" + check(priceBelow) + " - " + check(priceAbove);

  // https://www.walmart.com/search/?query=power%20bank%20charger

  // 1condition
  const condio = {
    used: "12319068031",
    new: "12319067031",
  }[itemCondition];

  // 1deal

  // 1delivery
  const isdfr =
    deliveryType &&
    {
      sameDay: "2-Day Shipping",
      nextDay: "2-Day Shipping",
    }[deliveryType];

  const maniReach = joinExisting(
    [
      //
      //   [includeOutStock, "p_n_availability:419162031"],
      [isdfr, `pickup_and_delivery:${isdfr}`],
      [dealTrue, "special_offers:Reduced Price"],
      //   [condio, `p_n_condition-type:${condio}`],
      //   [datInclude],
    ],
    "||"
  );

  const sortos = {
    //
    bestSeller: "best_seller",
    dateNew: "new",
    reviewHigh: "rating_high",
    priceLow: "price_low",
    priceHigh: "price_high",
  }[sortType];

  const sidfjer = matchTerms(data, {
    searchTerm: "query",
    brRefAff: "tag",
    brAffSub: "ascsubtag",
    priceBelow: "max_price",
    priceAbove: "min_price",
    // dateBefore:
    // dateAfter:
  });

  const countrios = {
    //
  };

  const sdwerase = {
    sort: sortos,
    facet: maniReach,
    ...sidfjer,
  };

  // https://www.walmart.com/grocery/search/?query=power%20bank%20charger&typeahead=

  const sidfr =
    //
    // "/search"
    "/grocery/search/";

  const iejrase = buildLink(sidfr, sdwerase);

  return iejrase;
}
