import { joinString } from "@sillyscribe95/bear-functions";
import { bearlog } from "@sillyscribe95/bear-ui-web";
import { defaultTerms } from "../const/testConst";

export function nameFormDict({
  dealTrue,
  deliveryType,
  itemCondition,
  searchTerm,
  website,
}) {
  //

  const asewa = joinString(website, ",");

  const sijfwe = [
    "🐻",
    "Compare",
    searchTerm,
    // asewa,
    defaultTerms[deliveryType],
    "delivery prices with SearchBear",
  ];

  const oneCompare =
    //
    joinString(sijfwe, " ");

  let finnios =
    //
    oneCompare;
  //

  bearlog.lug("namerformdict", { asewa, finnios });

  return finnios;
}
