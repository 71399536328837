import {
  capitalizeFirstLetter,
  checkType,
  maplisttostring,
} from "@sillyscribe95/bear-functions";
import { BearBorder, BearDiv, bearlog } from "@sillyscribe95/bear-ui-web";
import { PrepDiv } from "../containers/ChooseType";

export function prepareOption({
  //
  dict,
  tags,
  options,
  ...sfok
}) {
  options = options ? options : Object.entries(dict);
  const sifjee = options.map(isdfase);

  function isdfase(xcokda) {
    // let cvbfw = checkType(xcokda, "object")
    // let obbo = cvbfw ? xcokda : {val: xcokda}

    bearlog.lug("xcokda---", xcokda);

    return dfokre(xcokda);
  }

  function dfokre({ val, lab, ...xcvokdsf }) {
    const valios = tags ? maplisttostring(tags, " ") : "";
    const sijfw =
      //
      // val
      `${val}* ${lab} ${valios}`;

    const sdfer = {
      //
      lab,
      ...sfok,
    };

    // 1value
    const finalos = {
      label: PrepDiv(sdfer),
      value: sijfw,
    };

    return finalos;
  }

  //
  return sifjee;
}
