//

import { mapDictAttr } from "@sillyscribe95/bear-functions";
import { bearlog } from "@sillyscribe95/bear-ui-web";

//
export const countryConst = {
  gb: { name: "United Kingdom", tld: "co.uk", code: "uk" },
  uk: { name: "United Kingdom", tld: "co.uk", code: "uk" },
  us: { name: "United States", tld: "com", code: "us" },
  au: { name: "Australia", tld: "com.au", code: "au" },
  fr: { name: "France", tld: "fr" },
  ca: { name: "Canada", tld: "ca", code: "ca" },
  in: { name: "India", tld: "in" },
  br: { name: "Brazil", tld: "br" },
  mx: { name: "Mexico", tld: "mx" },
  de: { name: "Germany", tld: "de" },
  it: { name: "Italy", tld: "it" },
  es: { name: "Spain", tld: "es" },
};

export const countryFull = {
  ...countryConst,
  af: { name: "Afghanistan", tld: "af" },
  al: { name: "Albania", tld: "al" },
  dz: { name: "Algeria", tld: "dz" },
  as: { name: "American Samoa", tld: "as" },
  ad: { name: "Andorra", tld: "ad" },
  ao: { name: "Angola", tld: "ao" },
  ai: { name: "Anguilla", tld: "ai" },
  aq: { name: "Antarctica", tld: "aq" },
  ag: { name: "Antigua and Barbuda", tld: "ag" },
  ar: { name: "Argentina", tld: "ar" },
  am: { name: "Armenia", tld: "am" },
  aw: { name: "Aruba", tld: "aw" },
  au: { name: "Australia", tld: "com.au", code: "au" },
  at: { name: "Austria", tld: "at" },
  az: { name: "Azerbaijan", tld: "az" },
  bs: { name: "Bahamas", tld: "bs" },
  bh: { name: "Bahrain", tld: "bh" },
  bd: { name: "Bangladesh", tld: "bd" },
  bb: { name: "Barbados", tld: "bb" },
  by: { name: "Belarus", tld: "by" },
  be: { name: "Belgium", tld: "be" },
  bz: { name: "Belize", tld: "bz" },
  bj: { name: "Benin", tld: "bj" },
  bm: { name: "Bermuda", tld: "bm" },
  bt: { name: "Bhutan", tld: "bt" },
  bo: { name: "Bolivia", tld: "bo" },
  ba: { name: "Bosnia and Herzegovina", tld: "ba" },
  bw: { name: "Botswana", tld: "bw" },
  bv: { name: "Bouvet Island", tld: "bv" },
  io: { name: "British Indian Ocean Territory", tld: "io" },
  bn: { name: "Brunei", tld: "bn" },
  bg: { name: "Bulgaria", tld: "bg" },
  bf: { name: "Burkina Faso", tld: "bf" },
  bi: { name: "Burundi", tld: "bi" },
  kh: { name: "Cambodia", tld: "kh" },
  cm: { name: "Cameroon", tld: "cm" },
  cv: { name: "Cape Verde", tld: "cv" },
  ky: { name: "Cayman Islands", tld: "ky" },
  cf: { name: "Central African Republic", tld: "cf" },
  td: { name: "Chad", tld: "td" },
  cl: { name: "Chile", tld: "cl" },
  cn: { name: "China", tld: "cn" },
  cx: { name: "Christmas Island", tld: "cx" },
  cc: { name: "Cocos (Keeling) Islands", tld: "cc" },
  co: { name: "Colombia", tld: "co" },
  km: { name: "Comoros", tld: "km" },
  cg: { name: "Congo", tld: "cg" },
  ck: { name: "Cook Islands", tld: "ck" },
  cr: { name: "Costa Rica", tld: "cr" },
  hr: { name: "Croatia", tld: "hr" },
  cu: { name: "Cuba", tld: "cu" },
  cy: { name: "Cyprus", tld: "cy" },
  cz: { name: "Czech Republic", tld: "cz" },
  dk: { name: "Denmark", tld: "dk" },
  dj: { name: "Djibouti", tld: "dj" },
  dm: { name: "Dominica", tld: "dm" },
  do: { name: "Dominican Republic", tld: "do" },
  tl: { name: "East Timor", tld: "tl" },
  ec: { name: "Ecuador", tld: "ec" },
  eg: { name: "Egypt", tld: "eg" },
  sv: { name: "El Salvador", tld: "sv" },
  null: { name: "Wales", tld: null },
  gq: { name: "Equatorial Guinea", tld: "gq" },
  er: { name: "Eritrea", tld: "er" },
  ee: { name: "Estonia", tld: "ee" },
  et: { name: "Ethiopia", tld: "et" },
  fk: { name: "Falkland Islands", tld: "fk" },
  fj: { name: "Fiji Islands", tld: "fj" },
  fi: { name: "Finland", tld: "fi" },
  gf: { name: "French Guiana", tld: "gf" },
  pf: { name: "French Polynesia", tld: "pf" },
  tf: { name: "French Southern territories", tld: "tf" },
  ga: { name: "Gabon", tld: "ga" },
  gm: { name: "Gambia", tld: "gm" },
  ge: { name: "Georgia", tld: "ge" },
  gh: { name: "Ghana", tld: "gh" },
  gi: { name: "Gibraltar", tld: "gi" },
  gr: { name: "Greece", tld: "gr" },
  gl: { name: "Greenland", tld: "gl" },
  gd: { name: "Grenada", tld: "gd" },
  gp: { name: "Guadeloupe", tld: "gp" },
  gu: { name: "Guam", tld: "gu" },
  gt: { name: "Guatemala", tld: "gt" },
  gn: { name: "Guinea", tld: "gn" },
  gw: { name: "Guinea-Bissau", tld: "gw" },
  gy: { name: "Guyana", tld: "gy" },
  ht: { name: "Haiti", tld: "ht" },
  hm: { name: "Heard Island and McDonald Islands", tld: "hm" },
  va: { name: "Holy See (Vatican City State)", tld: "va" },
  hn: { name: "Honduras", tld: "hn" },
  hk: { name: "Hong Kong", tld: "hk" },
  hu: { name: "Hungary", tld: "hu" },
  is: { name: "Iceland", tld: "is" },
  id: { name: "Indonesia", tld: "id" },
  ir: { name: "Iran", tld: "ir" },
  iq: { name: "Iraq", tld: "iq" },
  ie: { name: "Ireland", tld: "ie" },
  il: { name: "Israel", tld: "il" },
  ci: { name: "Ivory Coast", tld: "ci" },
  jm: { name: "Jamaica", tld: "jm" },
  jp: { name: "Japan", tld: "jp" },
  jo: { name: "Jordan", tld: "jo" },
  kz: { name: "Kazakhstan", tld: "kz" },
  ke: { name: "Kenya", tld: "ke" },
  ki: { name: "Kiribati", tld: "ki" },
  kw: { name: "Kuwait", tld: "kw" },
  kg: { name: "Kyrgyzstan", tld: "kg" },
  la: { name: "Laos", tld: "la" },
  lv: { name: "Latvia", tld: "lv" },
  lb: { name: "Lebanon", tld: "lb" },
  ls: { name: "Lesotho", tld: "ls" },
  lr: { name: "Liberia", tld: "lr" },
  ly: { name: "Libyan Arab Jamahiriya", tld: "ly" },
  li: { name: "Liechtenstein", tld: "li" },
  lt: { name: "Lithuania", tld: "lt" },
  lu: { name: "Luxembourg", tld: "lu" },
  mo: { name: "Macao", tld: "mo" },
  mk: { name: "North Macedonia", tld: "mk" },
  mg: { name: "Madagascar", tld: "mg" },
  mw: { name: "Malawi", tld: "mw" },
  my: { name: "Malaysia", tld: "my" },
  mv: { name: "Maldives", tld: "mv" },
  ml: { name: "Mali", tld: "ml" },
  mt: { name: "Malta", tld: "mt" },
  mh: { name: "Marshall Islands", tld: "mh" },
  mq: { name: "Martinique", tld: "mq" },
  mr: { name: "Mauritania", tld: "mr" },
  mu: { name: "Mauritius", tld: "mu" },
  yt: { name: "Mayotte", tld: "yt" },
  md: { name: "Moldova", tld: "md" },
  mc: { name: "Monaco", tld: "mc" },
  mn: { name: "Mongolia", tld: "mn" },
  ms: { name: "Montserrat", tld: "ms" },
  ma: { name: "Morocco", tld: "ma" },
  mz: { name: "Mozambique", tld: "mz" },
  mm: { name: "Myanmar", tld: "mm" },
  na: { name: "Namibia", tld: "na" },
  nr: { name: "Nauru", tld: "nr" },
  np: { name: "Nepal", tld: "np" },
  nl: { name: "Netherlands", tld: "nl" },
  an: { name: "Netherlands Antilles", tld: "an" },
  nc: { name: "New Caledonia", tld: "nc" },
  nz: { name: "New Zealand", tld: "nz" },
  ni: { name: "Nicaragua", tld: "ni" },
  ne: { name: "Niger", tld: "ne" },
  ng: { name: "Nigeria", tld: "ng" },
  nu: { name: "Niue", tld: "nu" },
  nf: { name: "Norfolk Island", tld: "nf" },
  kp: { name: "North Korea", tld: "kp" },
  mp: { name: "Northern Mariana Islands", tld: "mp" },
  no: { name: "Norway", tld: "no" },
  om: { name: "Oman", tld: "om" },
  pk: { name: "Pakistan", tld: "pk" },
  pw: { name: "Palau", tld: "pw" },
  ps: { name: "Palestine", tld: "ps" },
  pa: { name: "Panama", tld: "pa" },
  pg: { name: "Papua New Guinea", tld: "pg" },
  py: { name: "Paraguay", tld: "py" },
  pe: { name: "Peru", tld: "pe" },
  ph: { name: "Philippines", tld: "ph" },
  pn: { name: "Pitcairn", tld: "pn" },
  pl: { name: "Poland", tld: "pl" },
  pt: { name: "Portugal", tld: "pt" },
  pr: { name: "Puerto Rico", tld: "pr" },
  qa: { name: "Qatar", tld: "qa" },
  re: { name: "Reunion", tld: "re" },
  ro: { name: "Romania", tld: "ro" },
  ru: { name: "Russian Federation", tld: "ru" },
  rw: { name: "Rwanda", tld: "rw" },
  sh: { name: "Saint Helena", tld: "sh" },
  kn: { name: "Saint Kitts and Nevis", tld: "kn" },
  lc: { name: "Saint Lucia", tld: "lc" },
  pm: { name: "Saint Pierre and Miquelon", tld: "pm" },
  vc: { name: "Saint Vincent and the Grenadines", tld: "vc" },
  ws: { name: "Samoa", tld: "ws" },
  sm: { name: "San Marino", tld: "sm" },
  st: { name: "Sao Tome and Principe", tld: "st" },
  sa: { name: "Saudi Arabia", tld: "sa" },
  sn: { name: "Senegal", tld: "sn" },
  rs: { name: "Serbia", tld: "rs" },
  sc: { name: "Seychelles", tld: "sc" },
  sl: { name: "Sierra Leone", tld: "sl" },
  sg: { name: "Singapore", tld: "sg" },
  sk: { name: "Slovakia", tld: "sk" },
  si: { name: "Slovenia", tld: "si" },
  sb: { name: "Solomon Islands", tld: "sb" },
  so: { name: "Somalia", tld: "so" },
  za: { name: "South Africa", tld: "za" },
  gs: { name: "South Georgia and the South Sandwich Islands", tld: "gs" },
  kr: { name: "South Korea", tld: "kr" },
  ss: { name: "South Sudan", tld: "ss" },
  lk: { name: "Sri Lanka", tld: "lk" },
  sd: { name: "Sudan", tld: "sd" },
  sr: { name: "Suriname", tld: "sr" },
  sj: { name: "Svalbard and Jan Mayen", tld: "sj" },
  sz: { name: "Swaziland", tld: "sz" },
  se: { name: "Sweden", tld: "se" },
  ch: { name: "Switzerland", tld: "ch" },
  sy: { name: "Syria", tld: "sy" },
  tj: { name: "Tajikistan", tld: "tj" },
  tz: { name: "Tanzania", tld: "tz" },
  th: { name: "Thailand", tld: "th" },
  cd: { name: "The Democratic Republic of Congo", tld: "cd" },
  tg: { name: "Togo", tld: "tg" },
  tk: { name: "Tokelau", tld: "tk" },
  to: { name: "Tonga", tld: "to" },
  tt: { name: "Trinidad and Tobago", tld: "tt" },
  tn: { name: "Tunisia", tld: "tn" },
  tr: { name: "Turkey", tld: "tr" },
  tm: { name: "Turkmenistan", tld: "tm" },
  tc: { name: "Turks and Caicos Islands", tld: "tc" },
  tv: { name: "Tuvalu", tld: "tv" },
  ug: { name: "Uganda", tld: "ug" },
  ua: { name: "Ukraine", tld: "ua" },
  ae: { name: "United Arab Emirates", tld: "ae" },
  uy: { name: "Uruguay", tld: "uy" },
  uz: { name: "Uzbekistan", tld: "uz" },
  vu: { name: "Vanuatu", tld: "vu" },
  ve: { name: "Venezuela", tld: "ve" },
  vn: { name: "Vietnam", tld: "vn" },
  vg: { name: "Virgin Islands, British", tld: "vg" },
  vi: { name: "Virgin Islands, U.S.", tld: "vi" },
  wf: { name: "Wallis and Futuna", tld: "wf" },
  eh: { name: "Western Sahara", tld: "eh" },
  ye: { name: "Yemen", tld: "ye" },
  zm: { name: "Zambia", tld: "zm" },
  zw: { name: "Zimbabwe", tld: "zw" },
};

// 1names
export const countryNames = mapDictAttr({}, countryFull, "name");

// 1console
bearlog.lug("countryNames", countryNames);
